import { useFetcher, useLocation } from "@remix-run/react";
import dayjs from "dayjs";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useRootData } from "~/utils/data/useRootData";
import { useCookRecipeSelectorContext } from "./cookRecipeContext";
import { usePopoverSelectorContext } from "./popoverContext";

const CalendarContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useCalendarContextVals = () => {
  const [open, setOpen] = useState(false);
  const [userCalOpen, setUserCalOpen] = useState(false);
  const [datesList, setDatesList] = useState([]);
  const calendarFetcher = useFetcher();
  const recipeCalFetcher = useFetcher();
  const recipes = useCookRecipeSelectorContext((state) => state.recipes);
  const location = useLocation();
  const locationRef = useRef();
  const rootData = useRootData();
  const openSide = usePopoverSelectorContext((state) => state.openSide);
  const closeSide = usePopoverSelectorContext((state) => state.closeSide);
  const sidesOpen = usePopoverSelectorContext((state) => state.sidesOpen);

  locationRef.current = location;

  useEffect(() => {
    if (sidesOpen.includes("userCal")) {
      setUserCalOpen(true);
    } else {
      setUserCalOpen(false);
    }
  }, [sidesOpen]);

  const addToDatesList = useCallback(
    (dates) => {
      if (dates && Array.isArray(dates)) {
        const newDates = uniqBy([...datesList, ...dates], "id");
        const sorted = sortBy(newDates, "id");
        console.log("sorted: ", sorted);
        const uniqDates = uniqBy(sorted, (value) => {
          const id = value.id;
          const date = value.date.split("T")[0];
          const time = value.time;
          const recipeId = value.recipeId;
          const servings = value.servings.toString();
          return `${id}-${date}-${time}-${recipeId}-${servings}`;
        });
        setDatesList(uniqDates);
      }
    },
    [datesList]
  );

  useEffect(() => {
    if (recipeCalFetcher.data) {
      addToDatesList(recipeCalFetcher.data.recipeDates ?? []);
    }
  }, [recipeCalFetcher.data]);

  const getRecipeSchedule = useCallback((recipeId) => {
    const form = new FormData();
    form.set("recipeId", recipeId);
    form.set("action", "get-schedule");
    recipeCalFetcher.submit(form, {
      method: "POST",
      action: "/api/recipe/schedule",
    });
  }, []);

  const getMonthRecipes = useCallback(
    (year, month) => {
      const form = new FormData();
      form.set("action", "get-month-recipes");
      form.set("month", `${year}-${month}`);
      recipeCalFetcher.submit(form, {
        method: "post",
        action: "api/recipe/schedule",
      });
    },
    [recipeCalFetcher]
  );

  // useEffect(() => {
  //   if (rootData.upcomingRecipes) {
  //     addToDatesList(rootData.upcomingRecipes);
  //   }
  // }, [rootData.upcomingRecipes]);

  useEffect(() => {
    if (calendarFetcher.data) {
      addToDatesList(calendarFetcher.data.recipesComingUp);
    }
  }, [calendarFetcher.data]);

  const openCalendar = () => {
    setOpen(true);
    openSide("cal");
  };

  const closeCalendar = () => {
    setOpen(false);
    closeSide("cal");
  };

  const openUserCalendar = () => {
    setUserCalOpen(true);
    openSide("userCal");
  };

  const closeUserCalendar = () => {
    setUserCalOpen(false);
    closeSide("userCal");
  };

  const recipeScheduleIdle = useMemo(() => {
    return recipeCalFetcher.state === "idle";
  }, [recipeCalFetcher.state]);

  const deleteDatePlan = (id: string, recipeId?: string) => {
    const form = new FormData();
    form.set("recipeDateId", id);
    if (recipeId) {
      form.set("recipeId", recipeId);
    }
    form.set("action", "deleteRecipeDate");
    recipeCalFetcher.submit(form, { action: `/api/recipe/schedule`, method: "POST" });
    //optimistically update the UI
    setDatesList((datesList) => datesList.filter((date) => date.id !== id));
  };

  const cleanDates = () => {
    setDatesList([]);
  };

  const createPlan = useCallback(
    (dateSelected, timeSelected, recipeId, servings, label, placeholder) => {
      // console.log("createPlan", dateSelected, timeSelected, recipeId, servings, label, placeholder);
      const form = new FormData();
      form.set("action", "schedule-recipe");
      form.set("date", dateSelected);
      form.set("time", timeSelected);
      form.set("recipeId", recipeId);
      form.set("servings", servings);
      form.set("label", label);
      form.set("placeholder", placeholder);
      recipeCalFetcher.submit(form, {
        method: "post",
        action: "api/recipe/schedule",
      });
      // const dayTime = dayjs(`${dateSelected} ${timeSelected[0]}:${timeSelected[1]} ${timeSelected[2]}`, "YYYY-MM-DD h:m a");
      // const newDate = {
      //   date: dateSelected,
      //   time: dayTime?.format("HH:mm"),
      //   recipeId,
      //   servings,
      //   label: Array.isArray(label) ? label[0] : label,
      //   placeholder,
      // };
      // addToDatesList([newDate]);
    },
    [datesList, recipeCalFetcher, recipes]
  );

  const createPlans = useCallback(
    (dateSelected, timeSelected, recipeServings, label, placeholder) => {
      const form = new FormData();
      form.set("action", "schedule-recipes");
      form.set("date", dateSelected);
      form.set("time", timeSelected);
      form.set("recipeServings", JSON.stringify(recipeServings));
      form.set("label", label);
      form.set("placeholder", placeholder);
      recipeCalFetcher.submit(form, {
        method: "post",
        action: "api/recipe/schedule",
      });
      const dayTime = timeSelected && dayjs(`${dateSelected} ${timeSelected[0]}:${timeSelected[1]} ${timeSelected[2]}`, "YYYY-MM-DD h:m a");
      const newDates = Object.keys(recipeServings).map((recipeId) => {
        const servings = recipeServings[recipeId];
        return {
          date: dateSelected,
          time: dayTime?.format("HH:mm"),
          recipeId,
          servings,
          label: Array.isArray(label) ? label[0] : label,
          placeholder,
        };
      });
      addToDatesList(newDates);
    },
    [datesList, recipeCalFetcher, recipes]
  );

  const numUpcomingRecipes = useMemo(() => {
    return datesList?.filter((r) => {
      return dayjs(r.date).isSame(dayjs(), "week") || dayjs(r.date).isSame(dayjs().add(1, "week"), "week");
    }).length;
  }, [datesList]);

  const handleChangeServings = (id: string, servings: number) => {
    const form = new FormData();
    form.set("action", "update-servings");
    form.set("recipeDateId", id);
    form.set("servings", servings.toString());
    recipeCalFetcher.submit(form, {
      method: "post",
      action: "api/recipe/schedule",
    });
  };

  return {
    open,
    setOpen,
    openCalendar,
    closeCalendar,
    datesList,
    getRecipeSchedule,
    recipeScheduleIdle,
    deleteDatePlan,
    getMonthRecipes,
    createPlan,
    cleanDates,
    openUserCalendar,
    closeUserCalendar,
    userCalOpen,
    numUpcomingRecipes,
    handleChangeServings,
    createPlans,
  };
};

const CalendarContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const calendarContext = useCalendarContextVals(props);

  return <CalendarContext.Provider value={{ ...calendarContext }}>{props.children}</CalendarContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useCalendarContext = () => useContext(CalendarContext);
const useCalendarSelectorContext = (selector: any) => {
  return useContextSelector(CalendarContext, selector);
};

export { CalendarContextProvider, useCalendarContext, useCalendarSelectorContext };
