import { useFetcher, useLocation, useNavigate, useNavigation } from "@remix-run/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { v4 as uuid } from "uuid";
import { closeConfirmModal, confirmModal } from "~/modules/recipe/components/confirmModal";
import { useRootData } from "~/utils/data/useRootData";
import supabase from "~/utils/integrations/supabaseClientService";
import { toTitleCase } from "~/utils/misc";
import { useCalendarSelectorContext } from "./calendarContext";
import { useCookRecipeSelectorContext } from "./cookRecipeContext";
import { usePopoverSelectorContext } from "./popoverContext";

const ShoppingListContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useShoppingListContextVals = () => {
  const [open, setOpen] = React.useState(false);
  const [shoppingLists, setShoppingLists] = useState([]);
  const [shoppingListItems, setShoppingListItems] = useState([]);
  const [shoppingRecipes, setShoppingRecipes] = useState([]);
  const shoppingListFetcher = useFetcher();
  const shoppingListItemFetcher = useFetcher();
  const shoppingRecipeFetcher = useFetcher();
  // const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const openCalendar = useCalendarSelectorContext((state) => state.openCalendar);
  const numUpcomingRecipes = useCalendarSelectorContext((state) => state.numUpcomingRecipes);

  const rootData = useRootData();
  const setThisRecipeId = useCookRecipeSelectorContext((state) => state.setThisRecipeId);
  const setThisCollectionId = useCookRecipeSelectorContext((state) => state.setThisCollectionId);

  const subscriptionRef = useRef();
  const shoppingDateRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const locationRef = useRef();
  const openSide = usePopoverSelectorContext((state) => state.openSide);
  const closeSide = usePopoverSelectorContext((state) => state.closeSide);
  const sidesOpen = usePopoverSelectorContext((state) => state.sidesOpen);

  locationRef.current = location;

  useEffect(() => {
    if (sidesOpen.includes("shoppingList")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sidesOpen]);

  const cleanShoppingLists = () => {
    setShoppingLists([]);

    setShoppingListItems([]);
  };

  const getRecipeDateInfo = (id: string) => {
    const form = new FormData();
    form.set("action", "getRecipeDateInfo");
    form.set("id", id);
    shoppingRecipeFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
  };

  const processDateChange = (data) => {
    if (data.eventType === "INSERT") {
      console.log("data.new: ", data.new);
      getRecipeDateInfo(data.new.id);
      //need to lookup the recipe info
    } else if (data.eventType === "UPDATE") {
      setShoppingRecipes((prevItems) =>
        prevItems.map((item) => {
          if (item.id === data.new.id) {
            return { ...item, date: data.new.date, time: data.new.time, servings: data.new.servings };
          }
          return item;
        })
      );
    } else if (data.eventType === "DELETE") {
      console.log("data.old: ", data.old);
    }
  };

  const processUpdate = (data) => {
    if (data.eventType === "INSERT") {
      setShoppingListItems((prevItems) => [...prevItems, data.new]);
    } else if (data.eventType === "UPDATE") {
      setShoppingListItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === data.new.id) {
            return data.new;
          }
          return item;
        })
      );
    } else if (data.eventType === "DELETE") {
      setShoppingListItems((prevItems) => prevItems.filter((item) => item.id !== data.old.id));
    }
  };

  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }
    if (rootData.userSession?.userId) {
      subscriptionRef.current = supabase
        .channel(`shopping-list-item`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "UserShoppingListItem",
            filter: `listId=in.(${shoppingLists.map((list) => list.id).join(", ")})`,
          },
          processUpdate
        )
        .subscribe();
    }
  }, [shoppingLists, rootData.userSession?.userId]);

  useEffect(() => {
    if (shoppingDateRef.current) {
      // console.log("should remove date subscription");
      shoppingDateRef.current.unsubscribe();
    }
    if (rootData.userSession?.userId) {
      // console.log("should create date subscription");
      shoppingDateRef.current = supabase
        .channel(`recipe-date`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "UserRecipeDate",
            filter: `userId=eq.${rootData.userSession?.userId}`,
          },
          processDateChange
        )
        .subscribe();
    }
  }, [rootData.userSession?.userId]);

  // const updateFormVals = useCallback(() => {
  //   setFormVals(form.getFieldsValue(true));
  // }, [form]);

  useEffect(() => {
    if (!rootData.hasSub) return;
    const form = new FormData();
    form.set("action", "getShoppingLists");
    shoppingListFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
  }, [rootData.hasSub,numUpcomingRecipes]);

  useEffect(() => {
    if (shoppingListFetcher.data && shoppingListFetcher.data.listItems) {
      setShoppingListItems(shoppingListFetcher.data.listItems ?? []);
    }
    if (shoppingListFetcher.data && shoppingListFetcher.data.shoppingLists) {
      setShoppingLists(shoppingListFetcher.data.shoppingLists ?? []);
    }
    if (shoppingListFetcher.data && shoppingListFetcher.data.recipes) {
      setShoppingRecipes(shoppingListFetcher.data.recipes ?? []);
    }
  }, [shoppingListFetcher.data]);

  //create effect when shoppingRecipeFetcher.data changes
  useEffect(() => {
    if (shoppingRecipeFetcher.data) {
      setShoppingRecipes((prevItems) => [...prevItems, shoppingRecipeFetcher.data.item]);
    }
  }, [shoppingRecipeFetcher.data]);

  const handleAddToShoppingList = useCallback(
    async (ingredient: any, recipeId?: string) => {
      const form = new FormData();
      const id = uuid();
      form.append("action", "addShoppingListItem");
      form.append("ingredient", ingredient.ingredient);
      form.append("quantity", ingredient.quantity);
      form.append("unit", ingredient.unit);
      form.append("id", id);
      if (recipeId) form.append("recipeId", recipeId);
      shoppingListFetcher.submit(form, {
        action: "/api/recipe/shoppingList",
        method: "post",
      });
      setShoppingListItems((slItems) => [
        ...slItems,
        { ingredient: ingredient.ingredient, quantity: ingredient.quantity, unit: ingredient.unit, recipeId: recipeId, id: id },
      ]);
    },
    [shoppingListFetcher]
  );

  const handleRemoveFromShoppingList = useCallback(
    async (id) => {
      setShoppingListItems((prevItems) => prevItems.filter((item) => item.id !== id));
      const form = new FormData();
      form.append("action", "removeShoppingListItem");
      form.append("id", id);
      shoppingListFetcher.submit(form, {
        action: "/api/recipe/shoppingList",
        method: "post",
      });
    },
    [shoppingListFetcher]
  );

  const clearForm = () => {
    closeConfirmModal();
  };

  const handleAddOneIngredientToShoppingList = useCallback((ingredient: any, recipeId?: string) => {
    closeConfirmModal();
    handleAddToShoppingList(ingredient, recipeId);
  }, []);

  const handleWholeRecipeToShoppingList = useCallback(
    (recipeId) => {
      closeConfirmModal();
      openCalendar && openCalendar();
      setThisRecipeId(recipeId);
      setThisCollectionId(null);
    },
    [openCalendar]
  );

  const handleAddOneOrAllToShoppingList = useCallback(
    async (ingredient, recipeId) => {
      confirmModal({
        header: "Add To Shopping List",
        title: toTitleCase(ingredient.ingredient),

        content: "Add only this ingredient or the whole recipe?",
        actions: [
          [
            {
              key: "one",
              text: <span className="line-clamp-2">{`Only ${ingredient.ingredient}`}</span>,
              color: "warning",
              onClick: () => handleAddOneIngredientToShoppingList(ingredient, recipeId),
            },
            {
              key: "all",
              text: "Whole Recipe",
              onClick: () => handleWholeRecipeToShoppingList(recipeId),
              // primary: true,
              default: true,
              className: "!bg-theme-500 hover:bg-theme-600 adm-button-default",
            },
          ],
        ],
      });
    },
    [shoppingListFetcher]
  );

  const completeShoppingListItem = (ids: string[]) => {
    setShoppingListItems((prevItems) =>
      prevItems.map((item) => {
        if (ids.includes(item.id)) {
          return { ...item, completed: true };
        }
        return item;
      })
    );
    const form = new FormData();
    form.set("action", "completeShoppingListItem");
    form.set("ids", ids.join(","));
    shoppingListItemFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
  };

  const deleteRecipeDateId = (id: string) => {
    setShoppingListItems((prevItems) =>
      prevItems.filter((item) => {
        item.recipeDateId !== id;
      })
    );
  };

  const deleteShoppingRecipe = (id: string) => {
    setShoppingRecipes((prevItems) => prevItems.filter((item) => item.recipeId !== id));

    setShoppingListItems((prevItems) =>
      prevItems.filter((item) => {
        return item.recipeId !== id;
      })
    );
    const form = new FormData();
    form.set("action", "removeShoppingListRecipe");
    form.set("id", id);
    shoppingListItemFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
  };

  const uncompleteShoppingListItem = (ids: string[]) => {
    const form = new FormData();
    form.set("action", "uncompleteShoppingListItem");
    form.set("ids", ids.join(","));
    shoppingListItemFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
    setShoppingListItems((prevItems) =>
      prevItems.map((item) => {
        if (ids.includes(item.id)) {
          return { ...item, completed: false };
        }
        return item;
      })
    );
  };

  const openShoppingList = () => {
    setOpen(true);
    openSide("shoppingList");
  };

  const closeShoppingList = () => {
    setOpen(false);
    closeSide("shoppingList");
  };

  const handleAddToPantry = () => {
    const form = new FormData();
    form.set("action", "addCompleteToPantry");
    shoppingListItemFetcher.submit(form, {
      action: "/api/recipe/shoppingList",
      method: "post",
    });
    setShoppingListItems((prevItems) => prevItems.filter((item) => !item.completed));
  };

  //TODO: shoppingRecipes is not updated when a date is created

  return {
    open,
    setOpen,
    openShoppingList,
    closeShoppingList,
    shoppingListItems,
    shoppingLists,
    shoppingRecipes,
    handleAddToShoppingList,
    handleRemoveFromShoppingList,
    handleAddOneOrAllToShoppingList,
    cleanShoppingLists,
    handleWholeRecipeToShoppingList,
    completeShoppingListItem,
    uncompleteShoppingListItem,
    deleteRecipeDateId,
    deleteShoppingRecipe,
    handleAddOneIngredientToShoppingList,
    handleAddToPantry,
  };
};

const ShoppingListContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const shoppingListContext = useShoppingListContextVals(props);

  return <ShoppingListContext.Provider value={{ ...shoppingListContext }}>{props.children}</ShoppingListContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useShoppingListContext = () => useContext(ShoppingListContext);
const useShoppingListSelectorContext = (selector: any) => {
  return useContextSelector(ShoppingListContext, selector);
};
export { ShoppingListContextProvider, useShoppingListContext, useShoppingListSelectorContext };

function ShoppingCartModalForm() {
  // const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});

  // const updateFormVals = useCallback(() => {
  //   setFormVals(form.getFieldsValue(true));
  // }, []);

  return <></>;
  // return (
  // <Form layout="horizontal" style={{ "--border-top": "none", "--border-bottom": "none" }} form={form} onValuesChange={updateFormVals}>
  //   <Form.Item name={"item"} style={{ "--prefix-width": "auto" }} childElementPosition="right">
  //     <Selector
  //       columns={2}
  //       // style={{ gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))" }}
  //       // className="z-10"
  //       options={[
  //         {
  //           label: "All",
  //           value: "all",
  //         },
  //         { label: `${ingredient.ingredient} Only`, value: "ingredient-only" },
  //       ]}
  //     />
  //   </Form.Item>
  //   {formVals.item?.includes("all") && (
  //     <Form.Item label="When" name={"when"}>
  //       <Selector
  //         columns={2}
  //         // style={{ gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))" }}
  //         // className="z-10"
  //         options={[
  //           {
  //             label: "This Week",
  //             value: "this-week",
  //           },
  //           {
  //             label: "Next Week",
  //             value: "next-week",
  //           },
  //         ]}
  //       />
  //     </Form.Item>
  //   )}
  // </Form>
  // );
}
